import React, { useState, useCallback, useContext, useEffect} from 'react'
import { IconButton } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close"
import { Dialog, Button, CircularProgress, Checkbox } from '@mui/material'
import { LabelInput } from '../../../components/LabelInput'
import SelectInput from '../../../components/SelectInput';
import { formatPhone, isPhone, isEmail, formatCPF, formatDate, formatDateToISOZulu, isValidDate, isDateOlderThanToday, isValidCPF, formatPhoneToFront, formatBirthdateToFront} from '../../../utils/utils'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { v4 as uuidv4 } from 'uuid'
import { Radio } from '@mui/material';
import { RadioGroup } from '@mui/material';
import { FormControlLabel } from '@mui/material';
import axios from 'axios'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import * as S from "../styles"
import AuthContext from "../../../context/auth-context"
import SearchBar from "../SearchBar"
import { DataField } from "../../../components/DataField"
import ContactsIcon from '@mui/icons-material/Contacts';
import { Pagination } from "@mui/material";
import { ErrorPage } from "../../AuxiliarPages/ErrorPage/ErrorPage"


function EnvelopeCreationDialog(props) {
    const { open, setOpen, handleToast } = props

    const [loading, setLoading] = useState(false)
    const company_key = useContext(AuthContext).user_data.company_key

    const [openDemoRequestModal, setOpenDemoRequestModal] = useState(false)

    const closeDemoRequestModal = () => {
        setOpenDemoRequestModal(false);
    };
    
    const primaryColor = "#0b1f82"

    const remindSelectOptions = [
        { value: 0, description: 'Não enviar lembretes' },
        { value: 1, description: 'A cada 1 dia' },
        { value: 2, description: 'A cada 2 dias' },
        { value: 3, description: 'A cada 3 dias' },
        { value: 7, description: 'A cada 7 dias' },
        { value: 14, description: 'A cada 14 dias' }
    ];

    const [reminderIntervalDayOption, setReminderIntervalDayOption] = useState(remindSelectOptions[0]['value']);

    const handleReminderChange = (value) => {
        setReminderIntervalDayOption(value);
    };

    const minorboxStyles = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        boxShadow: 24,
        height: 300,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      };

    const [errors, setErrors] = useState({
        subject: "",
        expiration_date: "",
        name: "",
        birthdate: "",
        document_number: "",
        email: "",
        phone: "",
        document_submission_method: "",
        authentication_submission_method: ""

    })

    const [touched, setTouched] = useState({
        subject: false,
        expiration_date: false,
        name: false,
        birthdate: false,
        document_number: false,
        email: false,
        phone: false,
        document_submission_method: false,
        authentication_submission_method: false,
    })

    const errorMessages = {
        phone: "Telefone inválido",
        email: "Email inválido",
        subject: "Assunto é obrigatório",
        expiration_date: "Data de expiração inválida",
        name: "Nome é obrigatório",
        birthdate: "Data de nascimento inválida",
        document_number: "CPF inválido",
        document_submission_method: "Método de envio dos documentos é obrigatório",
        authentication_submission_method: "Método de envio do código de autenticação é obrigatório"
    }

    const [signers, setSigners] = useState([])

    const [authenticationSubmissionErrors, setAuthenticationSubmissionErrors] = useState(Array(signers.length).fill(false));
    const [documentSubmissionErrors, setDocumentSubmissionErrors] = useState(Array(signers.length).fill(false));

    const handleBlur = (id, value) => {
        const error = !validateInput[id](value)
        setErrors({
            ...errors,
            [id]: error
        })
        setValues({
            ...values,
            [id]: value
        })
        setTouched((prevTouched) => ({
            ...prevTouched,
            [id]: true,
        }))
    }

    const handleSignerBlur = (index, id, value) => {
        setSigners([
            ...signers.slice(0, index),
            {
                ...signers[index],
                [id]: value
            },
            ...signers.slice(index + 1)
        ])
        setTouched((prevTouched) => ({
            ...prevTouched,
            [id]: true,
        }))
    }

    const [values, setValues] = useState({
        subject: "",
        expiration_date: "",
        signers: signers
    })

    const [attachedDocuments, setAttachedDocuments] = useState([])

    const resetForm = () => {
        setValues({
            subject: "",
            expiration_date: "",
            signers: [
                {
                    name: "",
                    birthdate: "",
                    document_number: "",
                    email: "",
                    phone: "",
                    document_submission_method: "",
                    authentication_submission_method: ""
                }
            ]
        })
        setSigners([
            {
                name: "",
                birthdate: "",
                document_number: "",
                email: "",
                phone: "",
                document_submission_method: "",
                authentication_submission_method: ""
            }
        ])
        setErrors({
            subject: "",
            expiration_date: "",
            name: "",
            birthdate: "",
            document_number: "",
            email: "",
            phone: "",
            document_submission_method: "",
            authentication_submission_method: ""
        })
        setAttachedDocuments([])
    }

    const validateForm = () => {
        const allValues = {
            ...values,
            signers: signers
        }

        const newDocumentSubmissionErrors = [...documentSubmissionErrors];
        const newAuthenticationSubmissionErrors = [...authenticationSubmissionErrors];

        signers.forEach((signer, index) => {
            if (!signer.document_submission_method){
                newDocumentSubmissionErrors[index] = true 
            }

            if (!signer.authentication_submission_method){
                newAuthenticationSubmissionErrors[index] = true 
            }
        })

        setDocumentSubmissionErrors(newDocumentSubmissionErrors)
        setAuthenticationSubmissionErrors(newAuthenticationSubmissionErrors)

        const allTouched = {
            ...touched,
            subject: true,
            name: true,
            birthdate: true,
            document_number: true,
            email: true,
            phone: true,
            document_submission_method: true,
            authentication_submission_method: true
        }

        setTouched(allTouched)

        const areSignersValid = Object.keys(allValues).every((key) => {
            if (key === "signers") {
                return values[key].every((signer) => {

                    if ((signer.authentication_submission_method === "email" || signer.document_submission_method === "email") && signer.email === "") {
                        return false
                    }

                    if ((signer.authentication_submission_method === "whatsapp" || signer.document_submission_method === "whatsapp") && signer.phone === "") {
                        return false
                    }

                    if ((signer.authentication_submission_method === "sms" || signer.document_submission_method === "sms") && signer.phone === "") {
                        return false
                    }
        
                    return Object.keys(signer).every((signerKey) => {
                        return validateInput[signerKey](signer[signerKey])
                    })
                })
            }
            return validateInput[key](values[key])
        })

        const areAttachedDocumentsValid = attachedDocuments.every((document) => {
            return document.name !== "" && document.content !== ""
        }) && attachedDocuments.length > 0

        if (areSignersValid && attachedDocuments.length === 0) {
            alert("É necessário anexar ao menos um documento.")
        }

        return areSignersValid && areAttachedDocumentsValid
    }
    
    const validateInput = {
        phone: (value) => {
            return value === "" || isPhone(value);
        },
        email: (value) => { 
            return value === "" || isEmail(value)
        },
        subject: (value) => value !== "",
        expiration_date: (value) => {
            return value === "" || isValidDate(value);
        },
        name: (value) => value !== "",
        birthdate: (value) => {
            return value === "" || isValidDate(value);
        },
        document_number: (value) => value !== "",
        document_submission_method: (value) => value !== "",
        authentication_submission_method: (value) => value !== ""
    }

    const handleInputChange = (index, field, value) => {
        const updatedSigners = [...signers]
        updatedSigners[index][field] = value
        setSigners(updatedSigners)
        const updatedValues = { ...values }
        updatedValues.signers = updatedSigners
        setValues(updatedValues)
    }

    const handleEnvelopeInputChange = (field, value) => {
        const updatedValues = { ...values }
        updatedValues[field] = value
        setValues(updatedValues)
    }

    const openContactGroupSelection = () => {
        setOpenContactGroupDialog(true)
    }
    
    const [filterContactGroupContent, setFilterContactGroupContent] = useState("");
    const [refresherContactGroup, setRefresherContactGroup] = useState(false);
    
    const handleContactGroupSearch = (queryParameter) => {
        setSearchContactGroup(queryParameter)
    };

    const handleContactGroup = (contactGroup) => {
        setContactGroupSelected(contactGroup)
        setOpenContactSelectionDialog(true)
    };
    
    const [searchContactGroup, setSearchContactGroup] = useState("");
    const [openContactGroupDialog, setOpenContactGroupDialog] = useState(false)
    const [contactGroupsData, setContactGroupsData] = useState(undefined)
    const [currentContactGroupPage, setCurrentContactGroupPage] = useState(1)
    const [maxContactGroupPage, setMaxContactGroupPage] = useState(1)
    const [contactGroupSelected, setContactGroupSelected] = useState(undefined)

    const [configContactGroups, setConfigContactGroups] = useState({
        params: {
            page_number: 0,
            page_rows: 7
        }
    })
    
    const [searchContact, setSearchContact] = useState("");
    const [openContactSelectionDialog, setOpenContactSelectionDialog] = useState(false)
    const [filterContactContent, setFilterContactContent] = useState("");
    const [refresherContact, setRefresherContact] = useState(false);
    const [contactsData, setContactsData] = useState(undefined)
    const [maxContactPage, setMaxContactPage] = useState(1)
    const [currentContactPage, setCurrentContactPage] = useState(1)

    const handleContactSearch = (queryParameter) => {
        setSearchContact(queryParameter)
    };

    const [configContacts, setConfigContacts] = useState({
        params: {
            page_number: 0,
            page_rows: 5
        }
    })

    const [selectedContacts, setSelectedContacts] = useState([]);
    
    const handleCheckboxChange = (contact) => {
        setSelectedContacts((prevSelected) =>
            prevSelected.some((selectedContact) => selectedContact.contact_key === contact.contact_key)
                ? prevSelected.filter((selectedContact) => selectedContact.contact_key !== contact.contact_key)
                : [...prevSelected, contact]
        );
    };

    const verifyContactOnCheckbox = (contact_key) => {
        for (let i = 0; i < selectedContacts.length; i++) {
            if (selectedContacts[i].contact_key === contact_key) {
                return true;
            }
        }
        return false;
    };

    const handleContactsSelect = () => {
        handleAddSignerFromContacts()
    };

    const [error, setError] = useState({
        hasError: false,
        statusCode: 200
    })

    const requestContactGroups = useCallback(() => {
        const timer = setTimeout(() => {
            const queryString = `${searchContactGroup ? `${searchContactGroup}&` : '?'}page_number=${configContactGroups.params.page_number}&page_rows=${configContactGroups.params.page_rows}`;
            
            axios.get(`/dash/sign/company/${company_key}/contact_groups${queryString}`)
                .then((response) => {
                    setContactGroupsData(response.data)
                    setMaxContactGroupPage(response.data.pagination.number_of_pages)
                })
                .catch((error) => {
                    setError({
                        "has_error": true,
                        "status": error.response.status
                    })
                })
        }, 500)
        return () => {
            clearTimeout(timer)
        }
    }, [configContactGroups, company_key, searchContactGroup])


    const requestContacts = useCallback(() => {
        const timer = setTimeout(() => {
            const queryString = `${searchContact ? `${searchContact}&` : '?'}page_number=${configContacts.params.page_number}&page_rows=${configContacts.params.page_rows}`;

            axios.get(`/dash/sign/company/${company_key}/contact_group/${contactGroupSelected.contact_group_key}/contacts${queryString}`)
            .then((response) => {
                setContactsData(response.data)
                setMaxContactPage(response.data.pagination.number_of_pages)
            })
            .catch((error) => {
                setError({
                    "has_error": true,
                    "status": error.response.status
                })
            })
        }, 500)
        return () => {
            clearTimeout(timer)
        }
    }, [configContacts, company_key, contactGroupSelected, searchContact])


    const handleContacts = () => {
        openContactGroupSelection(true)
    }

    const handleAddSignerFromContacts = () => {
        const updatedSigners = [...signers];
    
        for (const contact of selectedContacts) {
            const newSigner = {
                name: contact.contact_data.name, 
                birthdate: formatBirthdateToFront(contact.contact_data.birthdate), 
                document_number: contact.contact_data.document_number,
                email: contact.contact_data.email || "", 
                phone: formatPhoneToFront(contact.contact_data.phone), 
                document_submission_method: contact.contact_data.document_submission_method ,
                authentication_submission_method: contact.contact_data.authentication_submission_method 
            };
    
            updatedSigners.push(newSigner);
        }
    
        setSigners(updatedSigners);
        clearSelectedContacts();
    };
    
    useEffect(() => {
        let newConfigContactGroup;
        newConfigContactGroup = configContactGroups;
        newConfigContactGroup.params.page_number = currentContactGroupPage - 1;
        setConfigContactGroups(newConfigContactGroup);
        requestContactGroups();
      }, [currentContactGroupPage, configContactGroups, requestContactGroups]);


    useEffect(() => {
        let newConfigContact;
        newConfigContact = configContacts;
        newConfigContact.params.page_number = currentContactPage - 1;
        setConfigContacts(newConfigContact);

        if (contactGroupSelected) {
            requestContacts();
        }

      }, [currentContactPage, configContacts, contactGroupSelected, requestContacts]);

    const handleAddSigner = () => {
        const newSigner = {
            name: "",
            birthdate: "",
            document_number: "",
            email: "",
            phone: "",
            document_submission_method: "",
            authentication_submission_method: ""
        }
        const updatedSigners = [...signers, newSigner]
        setSigners(updatedSigners)
    }

    const handleRemoveSigner = (index) => {
        const updatedSigners = [...signers]
        updatedSigners.splice(index, 1)
        setSigners(updatedSigners)
    }

    const convertFileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader()
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result)
            }
        })
    }

    const handleAttachDocument = async (event) => {
        const files = event.target.files
        const updatedAttachedDocuments = [...attachedDocuments]
        for (let i = 0; i < files.length; i++) {
            const file = files[i]
            if (file.type !== 'application/pdf') {
                alert('O documento deve ser em formato PDF')
                return
            }
            if (file.size > 10000000) {
                alert('O documento deve ter no máximo 10MB')
                return
            }

            const document_b64 = await convertFileToBase64(file)
            const formmatedDocument = document_b64.replace('data:application/pdf;base64,', '')
            const attachedDocument = {
                id: uuidv4(),
                name: file.name,
                document_b64: formmatedDocument,
                document_type: 'pdf'
            }
            updatedAttachedDocuments.push(attachedDocument)
        }
        setAttachedDocuments(updatedAttachedDocuments)
    }

    const handleRemoveDocument = (documentId) => {
        const updatedAttachedDocuments = attachedDocuments.filter(
            (document) => document.id !== documentId
        )
        setAttachedDocuments(updatedAttachedDocuments)

        const fileInput = document.getElementById('attach-document');
        if (fileInput) {
            fileInput.value = null;
        }
    }

    const handleSubmit = async () => {
        const valid = validateForm()
        if (!valid) {
            return
        }

        setLoading(true)

        const formattedSigners = signers.map((signer) => {
            const formattedSigner = {
                id :  uuidv4(),
                name: signer.name,
                document_number: signer.document_number,
                document_submission_method: signer.document_submission_method,
                authentication_submission_method: signer.authentication_submission_method
            }

            if (signer.email !== "") {
                formattedSigner.email = (signer.email).trim()
            }

            if (signer.phone !== "") {
                const digitPhone = signer.phone.replace(/\D/g, '')
                formattedSigner.phone = {
                    international_dial_code: '55',
                    area_code: digitPhone.substring(0, 2),
                    number: digitPhone.substring(2, 11)
                }
            }

            if (signer.birthdate !== ""){
                formattedSigner.birthdate = formatDateToISOZulu(signer.birthdate, 'birthdate')
            }
            
            return formattedSigner
        })

        const submissionData = {
            id: uuidv4(),
            subject: values.subject,
            signers: formattedSigners
        }

        if (reminderIntervalDayOption !== 0){
            submissionData.reminder_settings = {
                reminder_days_interval: reminderIntervalDayOption
            }
        }

        if (values.expiration_date !== ""){
            submissionData.expiration_date = formatDateToISOZulu(values.expiration_date, 'expiration_date')
        }

        try {
            const response = await axios.post("/dash/sign/envelope", submissionData)
            await handleAttacheDocumentsSubmission(response.data.envelope_key)
            await submitEnvelope(response.data.envelope_key)
            handleToast("success", "Envelope criado com sucesso!")
            resetForm()
            setOpen(false)
            window.location.reload()
        } catch (error) {
            if ([403].includes((error.response || {}).status)) {
                setOpenDemoRequestModal(true);
            } else {
                console.error(error)
                handleToast("error", "Ocorreu um erro ao criar o envelope. Tente novamente.")
            }
           
        }
        setLoading(false)
    }

    const clearSelectedContacts = () => {
        setSelectedContacts([]);
    };

    const handleContactGroupPage = (event, page) => {
        if (currentContactGroupPage === page) {
        return;
        }
        setCurrentContactGroupPage(page);
    };

    const handleContactPage = (event, page) => {
        if (currentContactPage === page) {
        return;
        }
        setCurrentContactPage(page);
    };

    const handleAttacheDocumentsSubmission = async (envelopeKey) => {

        try {
            await Promise.all(
                attachedDocuments.map(async (signatureDocument) => {
                    const submissionData = {
                        id: uuidv4(),
                        name: signatureDocument.name,
                        document_b64: signatureDocument.document_b64,
                        document_type: signatureDocument.document_type,
                    }
                    await axios.post(`/dash/sign/envelope/${envelopeKey}/document`, submissionData)
                })
            )
        } catch (error) {
            console.error(error)
        }
    }

    const submitEnvelope = async (envelopeKey) => {
        const submissionData = {
            "status": "submitted"
        }

        try {
            await axios.patch(`/dash/sign/envelope/${envelopeKey}`, submissionData)
        } catch (error) {
            console.error(error)
        }
    }
    
    if (loading) {
        return (
            <Dialog open={open} onClose={() => setOpen(false)} >
                <div style={{ width: '400px', padding: '2rem', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
                    <CircularProgress />
                </div>
            </Dialog>
        )
    } else if (error.hasError) {
        return (
            <ErrorPage />
        ) }
    
    else { 

        return (
            <>
                <Dialog 
                open={open}
                slotProps={{
                    backdrop: {
                    style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' }
                    }
                }}
                >
                    <div style={{ width: '400px', padding: '2rem' }}>
                        <IconButton
                            aria-label="Close"
                            onClick={() => {
                                setOpen(false)
                                resetForm()
                            }}
                            style={{ float: "right" }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <h2 style={{ marginBottom: '1.5rem' }}>Criação de Envelope</h2>
                        <div>
                            <LabelInput
                                labelValue="Assunto"
                                value={values.subject}
                                onChange={(value) => handleEnvelopeInputChange("subject", value)}
                                onBlur={(value) => handleBlur("subject", value)}
                                error={touched.subject && values.subject === ""}
                                errorMessage={errorMessages.subject}
                            />
                        </div>
                        <div>
                            <LabelInput
                                labelValue="Data de Expiração (Opcional)"
                                value={values.expiration_date}
                                placeholder="DD/MM/AAAA"
                                onChange={(value) => handleEnvelopeInputChange("expiration_date", value)}
                                formatValue={(value) => formatDate(value)}
                                onBlur={(value) => handleBlur("expiration_date", value)}
                                error={(touched.expiration_date && ((!isValidDate(values.expiration_date) && values.expiration_date !== "") || (isDateOlderThanToday(values.expiration_date) && values.expiration_date !== "")))}
                                errorMessage={errorMessages.expiration_date}
                            />
                        </div>
                        <div>
                            <h3 style={{ marginBottom: '2px' }}>Envio de lembretes automáticos:</h3>
                            <SelectInput
                                items={remindSelectOptions}
                                valueKey="value"
                                labelKey="description"
                                onValueChange={handleReminderChange}
                                />
                        </div>
                        <h2 style={{ marginBottom: '1.5rem' }}>Signatários</h2>
                        {signers.map((signer, index) => (
                            <div key={index} style={{ border: '1px solid #ccc', padding: '1rem', marginBottom: '1.5rem', borderRadius: '8px' }}>
                                <h2 style={{ marginBottom: '1rem' }}>Signatário {index + 1}</h2>
                                <div>
                                    <LabelInput
                                        labelValue="Nome Completo"
                                        value={signer.name}
                                        onChange={(value) =>
                                            handleInputChange(index, "name", value)
                                        }
                                        onBlur={(value) => handleSignerBlur(index, "name", value)}
                                        error={touched.name && signer.name === ""}
                                        errorMessage={errorMessages.name}
                                    />
                                </div>
                                <div>
                                    <LabelInput
                                        labelValue="Data de Nascimento (Opcional)"
                                        value={signer.birthdate}
                                        placeholder="DD/MM/AAAA"
                                        onChange={(value) =>
                                            handleInputChange(index, "birthdate", value)
                                        }
                                        formatValue={(value) => formatDate(value)}
                                        onBlur={(value) => handleSignerBlur(index, "birthdate", value)}
                                        error={touched.birthdate && !isValidDate(signer.birthdate) && signer.birthdate !== ""}
                                        errorMessage={errorMessages.birthdate}
                                    />
                                </div>
                                <div>
                                    <LabelInput
                                        labelValue="CPF"
                                        value={signer.document_number}
                                        placeholder="000.000.000-00"
                                        onChange={(value) =>
                                            handleInputChange(index, "document_number", value)
                                        }
                                        onBlur={(value) => handleSignerBlur(index, "document_number", value)}
                                        formatValue={(value) => formatCPF(value)}
                                        error={(touched.document_number && signer.document_number === "") ||  (touched.document_number && !isValidCPF(signer.document_number))}
                                        errorMessage={errorMessages.document_number}
                                    />
                                </div>
                                <div>
                                    <LabelInput
                                        labelValue="E-mail"
                                        value={signer.email}
                                        onChange={(value) =>
                                            handleInputChange(index, "email", value)
                                        }
                                        onBlur={(value) => handleSignerBlur(index, "email", value)}
                                        error={touched.email && ((signer.document_submission_method === 'email' || signer.authentication_submission_method === 'email') && !isEmail(signer.email))}
                                        errorMessage={errorMessages.email}
                                    />
                                </div>
                                <div>
                                    <LabelInput
                                        labelValue="Telefone (Opcional)"
                                        value={signer.phone}
                                        placeholder="(00) 00000-0000"
                                        onChange={(value) =>
                                            handleInputChange(index, "phone", value)
                                        }
                                        formatValue={(value) => formatPhone(value)}
                                        onBlur={(value) => handleSignerBlur(index, "phone", value)}
                                        error={(touched.phone && !isPhone(signer.phone)) ||
                                        ((signer.document_submission_method === 'sms' || signer.authentication_submission_method === 'sms') && signer.phone === '') ||
                                        ((signer.document_submission_method === 'whatsapp' || signer.authentication_submission_method === 'whatsapp') &&  signer.phone === '') 
                                        }
                                        errorMessage={errorMessages.phone}
                                    />
                                </div>
                                <div>
                                    <h3 style={{ marginBottom: '1rem' }}>Método de Envio dos Documentos</h3>
                                    <RadioGroup
                                        row
                                        name="document_submission_method"
                                        value={signer.document_submission_method}
                                        onChange={(event) => handleInputChange(index, "document_submission_method", event.target.value)}
                                    >
                                        <FormControlLabel
                                            value="email"
                                            control={<Radio style={{ color: primaryColor, borderColor: primaryColor }} />}
                                            label="E-mail"
                                        />
                                        <FormControlLabel
                                            value="sms"
                                            control={<Radio style={{ color: primaryColor, borderColor: primaryColor }} />}
                                            label="SMS"
                                        />
                                        <FormControlLabel
                                            value="whatsapp"
                                            control={<Radio style={{ color: primaryColor, borderColor: primaryColor }}/>}
                                            label="WhatsApp"
                                        />
                                    </RadioGroup>
                                    {documentSubmissionErrors[index] && signer.document_submission_method === '' && <p style={{ color: '#B22300' }}>{errorMessages.document_submission_method}</p>}
                                </div>
                                <div>
                                    <h3 style={{ marginBottom: '1rem' }}>Método de Envio da Autenticação</h3>
                                    <RadioGroup
                                        row
                                        name="authentication_submission_method"
                                        value={signer.authentication_submission_method}
                                        onChange={(event) => handleInputChange(index, "authentication_submission_method", event.target.value)}
                                    >
                                        <FormControlLabel
                                            value="email"
                                            control={<Radio style={{ color: primaryColor, borderColor: primaryColor }} />}
                                            label="E-mail"
                                        />
                                        <FormControlLabel
                                            value="sms"
                                            control={<Radio style={{ color: primaryColor, borderColor: primaryColor }}/>}
                                            label="SMS"
                                        />
                                        <FormControlLabel
                                            value="whatsapp"
                                            control={<Radio style={{ color: primaryColor, borderColor: primaryColor }}/>}
                                            label="WhatsApp"
                                        />
                                        {authenticationSubmissionErrors[index] && signer.authentication_submission_method === '' && <p style={{ color: '#B22300' }}>{errorMessages.authentication_submission_method}</p>}
                                    </RadioGroup>
                                </div>
                                <Button
                                    variant="outlined"
                                    startIcon={<RemoveCircleOutlineIcon />}
                                    style={{ width: '100%', margin: '.5rem 0', color: primaryColor, borderColor: primaryColor }}
                                    onClick={() => handleRemoveSigner(index)}
                                >
                                    Remover Signatário
                                </Button>
                            </div>
                        ))}
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<AddCircleOutlineIcon />}
                            style={{ width: '100%', margin: '.5rem 0', color: primaryColor, borderColor: primaryColor }}
                            onClick={handleAddSigner}
                        >
                            Adicionar Signatário
                        </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<PermContactCalendarIcon />}
                            style={{ width: '100%', margin: '.5rem 0', color: primaryColor, borderColor: primaryColor }}
                            onClick={handleContacts}
                        >
                            Adicionar Signatário da Agenda
                        </Button>
                        <div>
                            <h2 style={{ marginBottom: '1rem' }}>Documentos Anexados</h2>
                            <input
                                type="file"
                                id="attach-document"
                                multiple
                                style={{ display: "none" }}
                                onChange={handleAttachDocument}
                            />
                            <label htmlFor="attach-document">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    startIcon={<AttachFileIcon />}
                                    style={{ width: '100%', margin: '.5rem 0', color: primaryColor, borderColor: primaryColor }}
                                    component="span"
                                    onClick={() => document.getElementById('attach-document')}
                                >
                                    Anexar Documento
                                </Button>
                            </label>
                            <ul>
                                {attachedDocuments.map((document) => (
                                    <li key={document.id}>
                                        {document.name}
                                        <IconButton
                                            aria-label="Remover"
                                            onClick={() => handleRemoveDocument(document.id)}
                                        >
                                            <RemoveCircleOutlineIcon />
                                        </IconButton>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div style={{ alignItems: 'center', textAlign: 'center' }}>
                            <Button 
                                variant="contained" 
                                color="primary" 
                                onClick={handleSubmit} 
                                style={{ width: '100%', margin: '2rem 0', backgroundColor: primaryColor }}
                            >
                                Enviar
                            </Button>
                        </div>
                    </div>
                </Dialog>
                {openDemoRequestModal && (
                    <>
                    <Modal open={openDemoRequestModal} onClose={closeDemoRequestModal}>
                        <Box sx={minorboxStyles}>
                        <S.MinorModalBoxContainer>
                            <ErrorOutlineOutlinedIcon
                            style={{ color: "red", fontSize: 40 }}
                            ></ErrorOutlineOutlinedIcon>
                            <S.InternalDialogContainerDescription>
                            A sua conta atingiu o limite máximo para criação de envelope teste.
                            Faça a ativação do seu plano e continue aproveitando os beneficios do Qi Sign.
                            </S.InternalDialogContainerDescription>
                            <S.MinorModalButtonContainer
                            onClick={() => closeDemoRequestModal()}
                            >
                            <S.ModalButtonDescription>
                                Voltar
                            </S.ModalButtonDescription>
                            </S.MinorModalButtonContainer>
                        </S.MinorModalBoxContainer>
                        </Box>
                    </Modal>
                    </>
                )}
                {openContactGroupDialog && (
                    <Dialog 
                    open={openContactGroupDialog}
                    slotProps={{
                        backdrop: {
                            style: { backgroundColor: 'rgba(0, 0, 0, 0)' }
                        }
                    }} 
                    >
                        <S.ContactGroupContainer>
                            <S.ContactBoxContainer>
                                <div style={{display:"flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: 16}}>
                                    <div style={{display: "flex", flexDirection: "row", gap: 4, alignItems: "center"}}>
                                        <PermContactCalendarIcon style={{color: "#0b1f82", fontSize: 24 }}></PermContactCalendarIcon>
                                        <h2>Grupos de contato</h2>

                                    </div>
                                    <IconButton
                                        aria-label="Close"
                                        onClick={() => {
                                            setOpenContactGroupDialog(false)
                                            setContactGroupSelected()
                                        }}
                                        style={{ float: "right", height:"24px", width:"24px"}}
                                    >
                                    <CloseIcon />
                                    </IconButton>
                                </div>
                                <SearchBar
                                    label="Pesquisar grupo de contatos"
                                    content={filterContactGroupContent} 
                                    setContent={setFilterContactGroupContent} 
                                    refresher={refresherContactGroup} 
                                    setRefresher={setRefresherContactGroup}
                                    onSearch={handleContactGroupSearch}
                                    variable={"name"}
                                />
                                <S.ContactBodyContainer> 
                                {contactGroupsData.contact_groups.map((contact_group) => (
                                    <S.ContactItem key={contact_group.contact_group}  onClick={() => handleContactGroup(contact_group)}>
                                        <DataField
                                            label="Nome do grupo"
                                            field={contact_group.name}
                                        />
                                        <div className="inbox__cell--right inbox__cell--row-container">
                                                <ContactsIcon
                                                    style={{ height: "16px", width: "16px" }}
                                                    id={contact_group.contact_group_key}
                                                />
                                        </div>
                                    </S.ContactItem>
                                ))}
                                </S.ContactBodyContainer>
                                <S.ContactPaginationContainer lock="true">
                                    <Pagination
                                        page={currentContactGroupPage}
                                        count={maxContactGroupPage}
                                        onChange={(event, page) => handleContactGroupPage(event, page)}
                                    />
                                </S.ContactPaginationContainer>
                                
                            </S.ContactBoxContainer>
                        </S.ContactGroupContainer>
                    </Dialog>
                )}
                {openContactSelectionDialog && contactsData && (
                    <Dialog 
                    open={openContactSelectionDialog} 
                    onClose={() => setOpenContactSelectionDialog(false)}
                    slotProps={{
                        backdrop: {
                        style: { backgroundColor: 'rgba(0, 0, 0, 0)' }
                        }
                    }} 
                    >
                        <S.ContactGroupContainer>
                            <S.ContactBoxContainer>
                                    <div style={{display:"flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: 16}}>
                                        <div style={{display: "flex", flexDirection: "row", gap: 4, alignItems: "center"}}>
                                            <PermContactCalendarIcon style={{color: "#0b1f82", fontSize: 24 }}></PermContactCalendarIcon>
                                            <h2>Lista de contatos</h2>

                                        </div>
                                        <IconButton
                                            aria-label="Close"
                                            onClick={() => {
                                                setOpenContactSelectionDialog(false);
                                                setContactGroupSelected(undefined); 
                                                clearSelectedContacts();
                                            }}
                                            style={{ float: "right", height:"24px", width:"24px"}}
                                        >
                                        <CloseIcon />
                                        </IconButton>
                                    </div>
                                    <SearchBar
                                        label="Pesquisar contato"
                                        content={filterContactContent} 
                                        setContent={setFilterContactContent} 
                                        refresher={refresherContact} 
                                        setRefresher={setRefresherContact}
                                        onSearch={handleContactSearch}
                                        variable={"contact_name"}
                                    />
                                <S.ContactBodyContainer style={{height: "260px"}}>
                                {contactsData.contacts && contactsData.contacts.map((contact) => (
                                    <S.ContactItem key={contact.contact_key} onClick={() => handleCheckboxChange(contact)}>
                                        <Checkbox
                                            checked={verifyContactOnCheckbox(contact.contact_key)}
                                            onChange={() => {}}
                                        />
                                        <DataField
                                            label="Nome do contato"
                                            field={contact.contact_name}
                                        />
                                    </S.ContactItem>
                                ))}
                                </S.ContactBodyContainer>                            
                                <S.ContactPaginationContainer style={{width: "340px"}}>
                                    <Pagination
                                        page={currentContactPage}
                                        count={maxContactPage}
                                        onChange={(event, page) => handleContactPage(event, page)}
                                    />
                                </S.ContactPaginationContainer>
                                <div style={{padding:"0 20px", position:"absolute", left:"0px", right:"0px", bottom: "8px"}}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            handleContactsSelect();
                                            setOpenContactSelectionDialog(false);
                                            setOpenContactGroupDialog(false);
                                        }}
                                        style={{ width: '100%', margin: '24px 0', backgroundColor: primaryColor }}
                                    >
                                    Selecionar
                                    </Button>


                                </div>
                                
                            </S.ContactBoxContainer>
                        </S.ContactGroupContainer>
                    </Dialog>
                )}
            </>
        )
    }
};

export default EnvelopeCreationDialog
